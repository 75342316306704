import React from "react"
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"
import ReviewCard from "../components/reviewCard";

const SearchPage = () => {

  const [show, setShow] = React.useState(false);
  const [field, setField] = React.useState("Overall Rating");
  const reviewList = [];

  const data = useStaticQuery(graphql`
    {
      allMdx {
        edges {
          node {
          frontmatter {
            address
            url
            title
            overall
            reviewDate
            extraGinger
            price
            background
            neighborhood
            fish
            quantity
            rice
            sauce
            toppings
          }
          slug
          }
        }
      }
    }
  `);

  const fieldMap = {
    "Overall Rating": "overall",
    "Ginger Rating": "extraGinger",
    "Price": "price",
    "Quantity": "quantity",
    "Fish Rating": "fish",
    "Rice Rating": "rice",
    "Topping Rating": "toppings",
    "Sauce Rating": "sauce",
  }

  const map = [
    "Overall Rating",
    "Ginger Rating",
    "Price",
    "Quantity",
    "Fish Rating",
    "Rice Rating",
    "Topping Rating",
    "Sauce Rating",
  ];

  data.allMdx.edges.forEach(review => {
    reviewList.push({
        name: review.node.frontmatter.title,
        slug: review.node.slug,
        url: review.node.frontmatter.url,
        neighborhood: review.node.frontmatter.neighborhood,
        address: review.node.frontmatter.address,
        reviewDate: review.node.frontmatter.reviewDate,
        background: review.node.frontmatter.background,
        overall: review.node.frontmatter.overall,
        extraGinger: review.node.frontmatter.extraGinger,
        price: review.node.frontmatter.price,
        quantity: review.node.frontmatter.quantity,
        fish: review.node.frontmatter.fish,
        rice: review.node.frontmatter.rice,
        toppings: review.node.frontmatter.toppings,
        sauce: review.node.frontmatter.sauce,
    });
  });

  const sortAsc = (arr, field) => {
    return arr.sort((a, b) => {
      if (a[field] > b[field]) { return 1; }
      if (b[field] > a[field]) { return -1; }
      return 0;
    })
  };

  const sortDesc = (arr, field) => {
      return arr.sort((a, b) => {
        if (a[field] > b[field]) { return -1; }
        if (b[field] > a[field]) { return 1; }
        return 0;
      })
  };

  const ListItem = ({name, selected}) => {
    return (
      <li onClick={()=>{setField(name) && setShow(false);}} 
        className="cursor-pointer select-none relative py-1 pr-9 hover:text-orange" 
        id="listbox-option-0" 
        aria-hidden="true"
      >
        <div className="flex items-center">
          <span className="font-normal ml-3 block truncate">
            {name}
          </span>
        </div>
        { selected && (
          <span className="text-orange absolute inset-y-0 right-0 flex items-center pr-2">
            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
          </span>
        )}
      </li>
    )
  }

  const Select = () => {
    return (
      <ul className="absolute mt-1 w-full text-black bg-white shadow-lg max-h-58 py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none" 
        tabindex="-1" 
        aria-labelledby="listbox-label" 
      >
        {map.map((name) => {
          let select = false;
          if (name === field) select = true;
          return (
            <ListItem name={name} selected={select}/>
          )
        })}
      </ul>
    )
  }

  const toggleShow = () => {
    if (show) {
      setShow(false);
    }
    else {
      setShow(true);
    }
  }

  field === "Price" ? sortAsc(reviewList, fieldMap[field]) : sortDesc(reviewList, fieldMap[field]);

  return (
    <Layout>
      <SEO title="Search" />
      <div className="container mx-auto p-4">
        <div className="h-14 flex sticky top-24 justify-center">
          <div className="bg-orange shadow-lg flex flex-row xs:flex-col absolute top-0 p-2 z-20 font-bold items-center justify-between gap-2"> 
            <div className="flex flex-row justify-between items-center gap-2">
              <label id="listbox-label" className="flex flex-row text-sm text-white items-center p-1">
                  sort by:
                  <div className="relative ml-2">
                    <button onClick={()=>toggleShow()} 
                      type="button" 
                      className="cursor-pointer relative w-44 bg-white  pr-10 py-1  text-left focus:outline-none focus:ring-2 focus:ring-blue focus:border-blue text-sm" 
                      aria-haspopup="listbox" 
                      aria-expanded="true" 
                      aria-labelledby="listbox-label"
                    >
                        <span className="flex items-center">
                            <span className="font-extrabold text-black ml-3 block truncate">
                                {field}
                            </span>
                        </span>
                        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg className="h-5 w-5 text-orange" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                            </svg>
                        </span>
                    </button>
                    {show && (
                      <Select/>
                    )}
                  </div>
              </label>                               

            </div>
          </div>
        </div>
        {reviewList.map((review) => (
          <ReviewCard name={review.name} neighborhood={review.neighborhood} rating={review[fieldMap[field]]} slug={review.slug} filter={field} background={review.background}/>
        ))}
      </div>
    </Layout>
  )
}

export default SearchPage
import React from "react";
import PropTypes from "prop-types"

import Rating from './rating'
import logo from "../images/Android-100-100.png";
import { navigate } from "gatsby-link";

const ReviewCard = ({name, neighborhood, rating, slug, filter, background}) => {
    let tailwind_bg = 'bg-white'
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    let filterField = (filter === "Price" ? (<div className="text-orange text-xl sm:text-sm xs:text-sm font-bold">{formatter.format(rating)}</div>) : (<Rating stars={rating} className="text-orange"/>));
    switch(background){
        case 'black':
            tailwind_bg = 'bg-black';
            break;
        case 'white':
            tailwind_bg = 'bg-white';
            break;
        default:
            tailwind_bg = 'bg-white';
            break;
    }
    return (
        <div onClick={()=>navigate("/reviews/".concat(slug))} 
            className="cursor-pointer container text-black border-none bg-blue bg-opacity-0 flex flex-row my-1 p-2 focus:outline-none focus:ring-blue focus:ring-1 hover:bg-opacity-25 hover:shadow-lg"
            aria-hidden="true"
        >
            <div className={`${tailwind_bg} flex items-center justify-center h-32 w-32 sm:h-20 sm:w-20 xs:h-20 xs:w-20 mr-2 p-1`}>
                <img alt={name} className="" src={require(`../images/${slug}/logo.png`)}/>
            </div>
            <div className="flex-grow flex-col">
                <div className="flex flex-row xs:flex-col items-center xs:items-baseline xs:gap-1">
                    <div className="flex-grow font-bold text-xl sm:text-md xs:text-md -mb-1">
                        {name}
                    </div>
                    <div className="flex-none sm:flex xs:flex h-8 sm:h-6 xs:h-6 items-center">
                        {filterField}
                    </div>
                </div>
                <div className="flex text-black text-opacity-75 text-lg sm:text-sm xs:text-sm">
                    {neighborhood}
                </div>
            </div>
        </div>
    );
}

ReviewCard.propTypes = {
    thumbnail: PropTypes.string,
    name: PropTypes.string,
    neighborhood: PropTypes.string,
    rating: PropTypes.number,
    slug: PropTypes.string,
    background: PropTypes.string,
}
  
ReviewCard.defaultProps = {
    thumbnail: logo,
    name: `Error`,
    neighborhood: `Error`,
    rating: 0,
    slug: `/reviews/`,
    background: 'white',
}

export default ReviewCard
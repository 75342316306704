import React from "react";

import { Star } from './svg/icons';

const Rating = ({stars, className=''}) => {
    const getStars = ({stars}) => {
        let rating = [];
        for (let i = 0; i < 6; i++) {
            if (i < stars) {
                rating.push(<Star className={`${className} fill-current h-5 sm:h-4 xs:h-4`}/>);
            }
            else {
                rating.push(<Star className={`${className} h-5 sm:h-4 xs:h-4`}/>);
            }
        }
        return rating;
    }

    return (
        <div className="flex flex-row gap-0">
            {getStars({stars})}
        </div>
    )
}

export default Rating